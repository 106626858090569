import { Text, Flex, Box, Title, createStyles } from "@mantine/core";
import ProductSplitPointer from "./ProductSplitPointer";

const useStyles = createStyles((theme) => ({
  main: {
    maxWidth: 560,
  },

  title: {
    marginBottom: 10,
    marginTop: 0,
  },
  para18: {
    textAlign: "left",
    fontSize: 18,
    lineHeight: 1.4,
    color: "#1a1a1acc",
    marginBottom: 0,
  },

  main_para: {
    marginTop: 24,
     
  },
}));

export default function Features(props) {
  const { classes } = useStyles();

  return (
    <Flex direction="column">
      <Box className={classes.main}>
        <Title size={34} className={classes.title}>
          {props.dataItem.heading}
        </Title>
        <Text className={classes.para18}>{props.dataItem.header_text}</Text>
        <Box
          className={classes.main_para}
        ></Box>
        {props.dataItem.items.map((item, index) => (
          <ProductSplitPointer key={index} dataItems={item} />
        ))}
      </Box>
    </Flex>
  );
}
