import {
  createStyles,
  Header,
  Group,
  Button,
  Divider,
  Burger,
  rem,
  Paper,
  Transition,
  Container,
  Image,
  ActionIcon,
  Text,
  UnstyledButton,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";
import VairflowLOGO from "../../assets/Vairflow_logo_horizontal_white.svg";
import { IconExternalLink } from "@tabler/icons-react";
import RequestButton from "../Button/RequestButton";
import { DASHBOARD_URL } from "../../config";
import { trackEvent } from "../../utils";

const HEADER_HEIGHT = 80;

const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: "#050038",
    borderBottom: 0,
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    maxWidth: 1280,
   
  },

  buttonleft: {
    color: theme.white,
    borderRadius: 20,

    "&:hover": {
      color: "#2e42ff",
    },

    [theme.fn.smallerThan("sm")]: {
      marginTop: 18,
    },
  },

  mobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  login: {
    alignItems: "center",
    justifyItems: "center",
    backgroundColor: "#2e42ff",
    borderRadius: 20,

    "&:hover": {
      backgroundColor: "#2434CC",
    },

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}));

export function Topbar() {
  // const [opened, { toggle, close }] = useDisclosure(false);
  const { classes } = useStyles();

  const handleLoginButtonClick = () => {
    trackEvent("Go to Dashboard clicked");
    window.open(DASHBOARD_URL, '_blank');
  };

  return (
    <Header height={HEADER_HEIGHT} mb={80} className={classes.root}>
      <Container className={classes.header}>
        <Image
          src={VairflowLOGO}
          width={150}
          height={50}
          fit="contain"
          alt="Vairflow Logo"
          className={classes.logo}
        ></Image>

        <Group p={20} spacing={40}>
          {/* <Button
            variant="subtle"
            rightIcon={<IconExternalLink size="1rem" />}
            className={classes.buttonleft}
          >
           
            View Docs
          </Button> */}

          {/* <Group spacing={5}className={classes.buttonleft}>
              <Text > View Docs</Text>
              <IconExternalLink size="1rem"/>
            </Group> */}

          <Button
            w={120}
            className={classes.login} 
            onClick={handleLoginButtonClick}  
          >
            Login
          </Button>


        </Group>

        {/* <Burger
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          size="sm"
          color="#fbfbfb"
        /> */}

        {/* <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown}  style={styles}>
              
              <Group position="center" grow pb="xl" px="md"pt="xl">
                <Button variant="default">Log in</Button>
                <Button className={classes.button}>Sign up</Button>
              </Group>
            </Paper>
          )}
        </Transition> */}
        {/* <Button
          className={classes.button}
          rightIcon={<IconExternalLink size="1rem" />}
          m={20}
        >
          View Docs
        </Button> */}
      </Container>
    </Header>
  );
}
