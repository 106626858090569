import { Box, createStyles } from "@mantine/core";

import HomeHeroSection from "./HeroSection/HomeHeroSection";
import MechanismSection from "./MechanismSection/SectionComponent";
import OtherFeaturesSection from "./OtherFeaturesSection/OtherFeaturesSection";
import { Topbar } from "./Topbar/Topbar";
import CTASection from "./CTASection/CTASection";
import Footer from "./Footer/Footer";

import { usePageTracking } from "../utils";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";

const useStyles = createStyles((theme) => ({
  main: {
    overflow: "visible",
    backgroundColor: "#050038",
  },
}));

export default function PageWrapper() {
  const { classes } = useStyles();

  usePageTracking("Landing page");

  return (
    <Box className={classes.main}>
      <Topbar />
      <HomeHeroSection />
      <MechanismSection />
      <OtherFeaturesSection />
      <CTASection />

      <Footer />
    </Box>
  );
}
