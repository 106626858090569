import {
  Badge,
  Box,
  Container,
  Flex,
  Grid,
  Image,
  Space,
  Stack,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import Features from "./FeaturesSection";
import gifIdeasToComponents from "../../assets/ideas_to_components.gif";
import gifDevelopDeploy from "../../assets/develop_deploy.gif";
import gifConnectServices from "../../assets/connect_services.gif";
import gifAIfeature from "../../assets/ai_feature.gif";
import gifLivePreview from "../../assets/live_preview.gif";


const data = [
  {
    coming_soon: false,
    heading: "Ideas to Components",
    gifImage: gifIdeasToComponents,
    header_text:
      "Simplify complex ideas into components. Each component can be:",
    items: [
      "Backend microservices with Flask, FastAPI, etc.",
      "Web UI with React, Next.js, Vue.js, etc.",
      "Mobile App UI for Android, iOS",
    ],
  },
  {
    coming_soon: false,
    heading: "Develop & Deploy",
    gifImage: gifDevelopDeploy,
    header_text:
      "Seamlessly develop your components and deploy them with a single click",
    items: [
      "Eliminate local environment setup hassles",
      "Deploy with one click",
      "Quickly monitor logs from different environments",
    ],
  },
  {
    coming_soon: false,
    heading: "Manage services seamlessly",
    gifImage: gifConnectServices,
    header_text:
      "Link your components and have a birds eye view of your architecture",
    items: [
      "Reuse same components for different apps and projects",
      "Modify or remove individual components without disrupting overall functionality",
    ],
  },
  {
    coming_soon: true,
    heading: "Code faster with AI",
    gifImage: gifAIfeature,
    header_text: "Work quickly and efficiently with AI assistance for:",
    items: [
      "Code Generation, Code Completion, Code Explanation",
      "Monitoring and tracking inter service dependencies",
      "Powered by Code Llama",
    ],
  },
  {
    coming_soon: true,
    heading: "Edit with Live Preview",
    gifImage: gifLivePreview,
    header_text:
      "Preview changes to your full-stack, multi-platform app as you edit it",
    items: [
      "Upcoming support for built-in multi-browser web previews",
      "Upcoming support for built-in Android and iOS emulators",
    ],
  }
];


const useStyles = createStyles((theme) => ({
  main: {
    paddingTop: 80,
    paddingBottom: 30,
    position: "relative",
    backgroundColor: "#fbfbfb",
    objectFit: "fill",
    overflow: "visible",
  },

  header: {
    textAlign: "center",
    fontSize: 48,
  },

  wrapper: {
    flexDirection: "row",
    marginLeft: "auto",
    marginRight: "auto",

    maxWidth: 1260,
    rowGap: 80,
    columnGap: 80,
    alignItems: "center",
    justifyContent: "center",

    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  wrapper_mobile: {
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
    rowGap: 60,
    columnGap: 60,
    alignItems: "center",
    justifyContent: "center",

    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },
}));


function HowItWorksFeature({ feature }) {
  return (
    <Stack>
      { feature.coming_soon && 
        <Box w={50}>
          <Badge color="indigo" variant="filled">
            Coming soon
          </Badge>
        </Box>
      }
      <Features dataItem={feature} />
    </Stack>
  );
}


export default function Body() {
  const { classes } = useStyles();

  const mockdata = data.map((feature, index) => (
    <Box key={index} mx={20}>
      <Image
        radius="lg"
        my={50}
        w="auto"
        fit="contain"
        src={feature.gifImage}
        alt="Image of various features of Vairflows"
        withPlaceholder
      />
      <HowItWorksFeature feature={feature} />
    </Box>
  ));

  return (
    <div className={classes.main}>
      <Container size="xl" px="xs">
        
        <Title className={classes.header}>How it works?</Title>
        <Space h={40} />

        <Flex className={classes.wrapper}>
          <Box>
            <Image
              radius="lg"
              my={50}
              w="auto"
              fit="contain"
              src={data[0].gifImage}
              withPlaceholder
            />
          </Box>
          <Features dataItem={data[0]} />
        </Flex>

        <Flex className={classes.wrapper}>
          <Features dataItem={data[1]} />
          <Box>
            <Image
              radius="lg"
              my={50}
              w="auto"
              fit="contain"
              src={data[1].gifImage}
              alt="With default placeholder"
              withPlaceholder
            />
          </Box>
        </Flex>

        <Flex className={classes.wrapper}>
          <Box>
            <Image
              radius="lg"
              my={50}
              fit="contain"
              src={data[2].gifImage}
              alt="With default placeholder"
              withPlaceholder
            />
          </Box>
          <Features dataItem={data[2]} />
        </Flex>

        <Flex className={classes.wrapper}>
          <HowItWorksFeature feature={data[3]} />
          <Box>
            <Image
              radius="lg"
              my={50}
              fit="contain"
              src={data[3].gifImage}
              alt="With default placeholder"
              withPlaceholder
            />
          </Box>
        </Flex>

        <Flex className={classes.wrapper}>
          <Box>
            <Image
              radius="lg"
              my={50}
              fit="contain"
              src={data[4].gifImage}
              alt="With default placeholder"
              withPlaceholder
            />
          </Box>
          <HowItWorksFeature feature={data[4]} />
        </Flex>

        <Flex className={classes.wrapper_mobile}>{mockdata}</Flex>
        
      </Container>
    </div>
  );
}
