import { Button, createStyles } from "@mantine/core";

import { DASHBOARD_URL } from "../../config";
import { trackEvent } from "../../utils";


const useStyles = createStyles((theme) => ({
  button: {
    alignItems: "center",
    justifyItems: "center",
    backgroundColor: "#2e42ff",
    borderRadius: 20,
  
    "&:hover": {
      backgroundColor: "#2434CC",
    },
  },
}));

export default function RequestButton() {
  
  const { classes } = useStyles();

  const handleButtonClick = () => {
    trackEvent("Go to Dashboard clicked");
    window.open(DASHBOARD_URL, '_blank');
  };

  return (
    <Button
      size="md"
      className={classes.button}
      onClick={handleButtonClick}
    >
      Try Vairflow for free
    </Button>
  );
}
