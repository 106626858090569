import React from "react";
import { Container, Image, createStyles } from "@mantine/core";

import heroGIF from "../../assets/ai_feature.gif";

const useStyles = createStyles((theme) => ({
  
  heroImage_mobile: {
    [theme.fn.smallerThan("sm")]:{
      marginLeft: 20,
      marginRight:20
    }
   
  }
}));

export default function HeroImage() {
  const { classes } = useStyles();
  return (
    <Container size="lg" px="xs" className={classes.heroImage_mobile}  >
      <Image
        radius="lg"
        fit="contain"
        src={heroGIF}
        alt="Main image of Vairflows showing Vairflow's core features"  
      />
    </Container>
  );
}
