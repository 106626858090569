import { Card, Image, Text, createStyles } from "@mantine/core";
import image1 from "../../assets/no_vendor_lockin.png";
import image2 from "../../assets/pay_for_what_you_use.png";
import image3 from "../../assets/easy_context_switch.png";


const  data = [{
  image: image1,
  header: "No Vendor Lock-in",
  para: "You can deploy with us or choose to deploy on your AWS, Google Cloud, or On-premise"
},
{
  image: image2,
  header: "Pay for what you use",
  para: "You will only be charged for the actual usage and not for the idle time"
},
{
  image: image3,
  header: "Easy context switch",
  para: "YSeamlessly Transition Between Projects and Environments"
},
]

const useStyles = createStyles((theme) => ({
    main: {
        backgroundColor: "#fbfbfb",
    },
  
  }))

export default function FeatureCard() {
    const { classes } = useStyles();



  return (
    
   <div>
      {data.map((item, index) => (
        <Card padding="xl" component="a" className={classes.main} key={index}>
          <Card.Section>
            <Image src={item.image} alt={item.header} />
          </Card.Section>
          <Text weight={700} size={24} mt={24}>
            {item.header}
          </Text>
          <Text mt="xs" color="#1a1a1acc" size={16}>
            {item.para}
          </Text>
        </Card>
      ))}
    </div>
   
  );
}
