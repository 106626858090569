import {
  Box,
  Button,
  Flex,
  Space,
  Text,
  createStyles,
  Container,
} from "@mantine/core";
import RequestButton from "../Button/RequestButton";

const useStyles = createStyles((theme) => ({
  h1: {
    fontSize: 56,
    fontWeight: 800,
    color: theme.white,
    lineHeight: 1.2,
    width: 580,

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
  desktop: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  h1_tablet: {
    [theme.fn.smallerThan("sm")]: {
      fontSize: 44,
      fontWeight: 800,
      color: theme.white,
      maxWidth: "80vw",
      textAlign: "center",
      lineHeight: 1.2,
    },

    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },
   
  tablet: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  h1_mobile: {
    [theme.fn.smallerThan("xs")]: {
      fontSize: 42,
      fontWeight: 800,
      color: theme.white,
      maxWidth: "95vw",
      textAlign: "center",
      lineHeight: 1.2,
    },
  },

  h2: {
    fontSize: 20,
    fontWeight: "normal",
    color: "#fbfbfbcc",
    textAlign: "center",
    lineHeight: 1.4,
    maxWidth: 460,
    marginBottom: 30,
    marginTop: 20,
  },

  mobile: {
    [theme.fn.largerThan("xs")]: {
      display: "none",
    },
  },

  button: {
    alignItems: "center",
    justifyItems: "center",
    backgroundColor: "#2e42ff",
    borderRadius: 20,

    "&:hover": {
      backgroundColor: "#2434CC",
    },
  },
}));

export default function HeaderBlock() {
  const { classes } = useStyles();

  return (
    <Box>
      <Flex
        align="center"
        justify="center"
        direction="column"
        className={classes.desktop}
      >
        <Text className={classes.h1}>
          Next-Gen IDE for your services in the Cloud
        </Text>

        <Container className={classes.h2}>
          Build faster, build more with our AI-driven IDE
        </Container>
        <RequestButton />
      </Flex>
      <Flex
        justify="center"
        align="center"
        direction="column"
        className={classes.tablet}
      >
        <Text className={classes.h1_tablet}>
          Next-Gen IDE for your services in the Cloud
        </Text>
        <Container className={classes.h2}>
          Build faster, build more with our AI-driven IDE
        </Container>
        <RequestButton />
      </Flex>

      <Flex
        justify="center"
        align="center"
        direction="column"
        className={classes.mobile}
      >
        <Text className={classes.h1_mobile}>
          Next-Gen IDE for your services in the Cloud
        </Text>

        <Container className={classes.h2}>
          Build faster, build more with our AI-driven IDE
        </Container>

        <RequestButton />
      </Flex>
    </Box>
  );
}
