import { Box, Space, createStyles } from "@mantine/core";
import HeaderBlock from "./HeaderBlock";
import HeroImage from "./HeroImage";


const useStyles = createStyles((theme) => ({
  wrapper: {
    overflow: "visible",
    paddingBottom: 100,
    backgroundColor: "#050038"
    
  },
  heroImage_mobile: {
    [theme.fn.smallerThan("sm")]:{
      marginLeft: 20,
      marginRight:20
    }
   
  }
}));

export default function HomeHeroSection() {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <HeaderBlock />
      <Space h={60}/>
      <HeroImage className={classes.heroImage_mobile}/>
    </div>
  );
}
