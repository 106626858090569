import { Flex, Image, Text, createStyles } from "@mantine/core";
import checkMark from "../../assets/check_mark.svg";

const useStyles = createStyles((theme) => ({
  wrapper:{
    marginBottom: 16

  },
    para: {
        textAlign: "left",
        fontSize: 16,
        lineHeight: 1.4,
        color: "#1a1a1acc",
        marginBottom: 0,
      },

}))

export default function ProductSplitPointer(props) {
    const { classes } = useStyles();

  return (
    <Flex gap={12} className={classes.wrapper}>
      <Image
        width={28}
        height={28}
        maw={"100%"}
        src={checkMark} 
        alt="Check mark icon"
      />
      <Text className={classes.para}>
        {props.dataItems}
      </Text>
    </Flex>
  );
}
