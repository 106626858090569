import { MantineProvider } from "@mantine/core";
import PageWrapper from "./components/PageWrapper";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Terms from "./components/Terms/Terms";



function App() {
  

  return (
    <Router>
      <MantineProvider
        theme={{
          components: {
            Container: {
              defaultProps: {
                sizes: {
                  xs: 540,
                  sm: 720,
                  md: 650,
                  lg: 1080,
                  xl: 1280,
                },
              },
            },
          },
        }}
      >
        <Routes>
          <Route path="*" element={<PageWrapper />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </MantineProvider>
    </Router>
  );
}

export default App;
