
import { useEffect } from 'react';


export const usePageTracking = (pageName) => {
  useEffect(() => {
    if (window.analytics) {
      window.analytics.page(pageName);
    }
  }, [pageName]);
};


export function trackEvent(eventName, properties) {
  if (window.analytics) {
    window.analytics.track(eventName, properties);
  }
};

