import {
  Box,
  Card,
  Container,
  Flex,
  Grid,
  Image,
  Space,
  Text,
  Title,
  createStyles,
} from "@mantine/core";

import FeatureCard from "./FeatureCard";

import image1 from "../../assets/no_vendor_lockin.png";
import image2 from "../../assets/pay_for_what_you_use.png";
import image3 from "../../assets/easy_context_switch.png";

const data = [
  {
    image: image1,
    header: "No Vendor Lock-in",
    para: "You can deploy with us or choose to deploy on your AWS, Google Cloud, or On-premise",
  },
  {
    image: image2,
    header: "Pay for what you use",
    para: "You will only be charged for the actual usage and not for the idle time",
  },
  {
    image: image3,
    header: "Easy context switch",
    para: "Seamlessly Transition Between Projects and Environments",
  }
];

const useStyles = createStyles((theme) => ({
  main: {
    paddingTop: 80,
    paddingBottom: 30,
    position: "relative",
    overflow: "visible",
    backgroundColor: "#e7f8fa",
  },

  wrapper: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 1260,

    [theme.fn.smallerThan("lg")]: {
      marginLeft: 20,
      marginRight: 20,
    },
  },

  header: {
    textAlign: "left",
    fontSize: 48,
  },
}));

export default function OtherFeaturesSection() {
  const { classes } = useStyles();

  return (
    <div className={classes.main}>
      <div className={classes.wrapper}>
        <Container size="xl" px="xs">
          <Text fz={40} fw={700} ta="center">
            Empower Your Development
          </Text>
          <Text ta="center" color="#1a1a1acc">
            Flexible deployment, cost-effective usage, and seamless
            collaboration.
          </Text>
          <Space h={60} />

          <Grid gutter={10} gutterXs="md" gutterMd="xl" gutterXl={50} pb={40}>
            {data.map((item, index) => (
              <Grid.Col sm={6} lg={4} key={index}>
                <Card bg="transparent" >
                  <Image
                    fit="contain"
                    height={250}
                    src={item.image}
                    alt={item.header}
                  />

                  <Text weight={700} size={24} mt={40}>
                    {item.header}
                  </Text>
                  <Text mt="xs" color="#1a1a1acc" size={16}>
                    {item.para}
                  </Text>
                </Card>
              </Grid.Col>
            ))}
          </Grid>
        </Container>
      </div>
    </div>
  );
}
