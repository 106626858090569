import {
  Button,
  Center,
  Container,
  Space,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import { useEffect, useState } from "react";
import RequestButton from "../Button/RequestButton";

const useStyles = createStyles((theme) => ({
  wrapper: {
    backgroundColor: "050038",
  },

  para: {
    textAlign: "center",
    fontSize: 18,
    lineHeight: 1.2,
    color: "#fbfbfbcc",
  },
}));

export default function CTASection() {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <Container size="md" px="xs" py={100}>
        <Title size={48} color="#fbfbfb" align="center">
          Work Smarter, Not Harder!
        </Title>
        <Space h={15} />
        <Text className={classes.para}>
          Let us do the heavy lifting, while you focus on the bigger picture
        </Text>
        <Space h={40} />
        <Center>
          <RequestButton />
        </Center>
      </Container>
    </div>
  );
}
