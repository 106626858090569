import {
  Container,
  Flex,
  Group,
  Image,
  Text,
  createStyles,
  rem,
} from "@mantine/core";
import VairflowLogo from "../../assets/new_logo_dark_website.svg";
import Linkedin from "../../assets/linkedin copy.svg";
import Twitter from "../../assets/icons8-twitter copy.svg";
import Mail from "../../assets/mail-filled.svg";
import { useNavigate } from "react-router-dom";



const useStyles = createStyles((theme) => ({
  main: {
    paddingTop: 80,
    paddingBottom: 30,
    position: "relative",
    overflow: "visible",
    backgroundColor: "#fbfbfb",
  },

  wrapper: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 1260,

    [theme.fn.smallerThan("lg")]: {
      marginLeft: 20,
      marginRight: 20,
    },
  },

  boxLeft: {
    maxWidth: 320,
  },

  link: {
    display: "block",
    color: "#1a1a1a",
    fontSize: 18,
    lineHeight: 1.2,
    paddingTop: rem(3),
    paddingBottom: rem(3),
    "&:hover": {
      color: "#2e42ff",
    },
  },

  icon: {
    "&:hover": {
     backgroundColor: theme.colors.gray[3],
    },
  },
}));

export default function Footer() {
  const { classes } = useStyles();

  const navigate = useNavigate();


  return (
    <div className={classes.main}>
      <div className={classes.wrapper}>
        <Container size="xl" px="xs">
          <Group position="apart">
            <Flex direction="column">
              <Image
                maw={150}
                height={50}
                fit="contain"
                alt="Vairflow Logo"
                src={VairflowLogo}
              />
              <Text size={16} fw={400} c="#1a1a1a" maw={250} mt={10}>
                3 Germay Dr, Unit 4 #2890 Wilmington, Delaware 19804
              </Text>
              <Flex
                gap="md"
                justify="flex-start"
                align="flex-start"
                direction="row"
                mt={30}
              >
                <a href="https://www.linkedin.com/company/vairflow" target="_blank">
                  <Image
                    width={30}
                    height={30}
                    src={Linkedin}
                    alt="Link to LinkedIn profile"
                    className={classes.icon}
                  />
                </a>
                <a href="https://twitter.com/VairflowInc" target="_blank">
                  <Image
                    width={30}
                    height={30}
                    src={Twitter}
                    alt="Link to Twitter profile"
                    className={classes.icon}/>
                </a>

                <a href="mailto:contact@vairflow.com" target="_blank">
                  <Image
                    width={32}
                    height={30}
                    src={Mail}
                    alt="Email Vairflow"
                    className={classes.icon}
                  />
                </a>
              </Flex>
            </Flex>
            <Flex direction="column" justify="flex-start" align="flex-start">
              <Text size={20} fw={600}>
                Company
              </Text>
              <Flex
                justify="flex-start"
                align="flex-start"
                direction="column"
                mt={10}
              >
                <Text
                  className={classes.link}
                  component="a"
                  href="/"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Home
                </Text>
                <Text
                  className={classes.link}
                  component="a"
                  href="/privacy"
                  onClick={() => {
                    navigate("/privacy");
                  }}
                >
                  Privacy Policy
                </Text>
                <Text
                  className={classes.link}
                  component="a"
                  href="/terms"
                  onClick={() => {
                    navigate("/terms");
                  }}
                >
                  Terms of Service
                </Text>
              </Flex>
            </Flex>
          </Group>
          <Text mt={80}>
            Copyright © 2023 Vairflow, Inc. All rights reserved.
          </Text>
        </Container>
      </div>
    </div>
  );
}
